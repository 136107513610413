<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="page-container block-container">
      <div class="column left-block large-block">
        <h1>Support</h1>
        <p>Support nodig bij webhosting, zakelijke hosting of uw Virtual Private Server? Ons team van specialisten in hosting staat graag voor u klaar. We kunnen u de technische ondersteuning en support bieden bij specifieke vragen die u heeft of u adviseren over de verschillende producten en diensten die er beschikbaar zijn.</p>
        <p>U kunt vanuit de support voor hosting inloggen op het <a href="https://cp.hostingondemand.nl/">Klantenpaneel</a> of bladeren door de antwoorden op veel gestelde vragen, zodat u het wellicht meteen kunt oplossen en u weer verder kunt. Komt u er zelf niet uit? Dan kunt u contact opnemen met onze support.</p>
        <img
          class="framed-image"
          src="../../assets/images/helpdesk.png"
          height="446"
          width="526"
          title="hosting on demand support"
          alt="hosting on demand support"
        >
        <h3>Technische ondersteuning en support</h3>
        <p>Het team van support is gespecialiseerd in alle vormen van hosting en kan u antwoord geven op de meest technische vragen. We zorgen ervoor dat uw pakket voor webhosting, zakelijke hosting of de Virtual Private Server goed werkt, zodat u daar optimaal gebruik van kunt maken. Bovendien kunnen we u helpen de verschillende oplossingen met elkaar te vergelijken, zodat u daar een goede keuze uit kunt maken. Waar het u ook om gaat, wij zorgen ervoor dat u de technische hosting support ontvangt die u zoekt, waardoor u er op dat gebied niet alleen voor staat.</p>
        <p><i>“Fijn dat jullie de technisch soms lastige materie zo helder kunnen uitleggen. Bedankt voor de support, wat mij betreft een absolute aanrader”</i>, aldus een tevreden klant.</p>
        <h3>Mijn Hosting on Demand-omgeving</h3>
        <p>Vanuit het <a href="https://cp.hostingondemand.nl/">Klantenpaneel</a> kunt u een aantal belangrijke zaken regelen, waardoor u daar mogelijk geen support bij nodig heeft. We maken het beheer van de online omgeving eenvoudig. Vanuit het beheerspaneel heeft u toegang tot uw e-mail, de webhosting en bijvoorbeeld de statistieken over de website. Bekijk de verschillende instellingen en pas die aan uw wensen aan. De gebruiksvriendelijke online omgeving zorgt ervoor dat we u support kunnen bieden voor de lastige technische vragen, waar u zelf niet uitkomt.</p>
        <h3>Veel gestelde vragen over onze diensten</h3>
        <p>Heeft u technische vragen over onze diensten? Ook daarvoor is de support-afdeling het ideale adres. We kunnen u aangeven hoe de verschillende diensten voor hosting van elkaar verschillen en wat op basis van uw voorkeuren de beste keuze is. We adviseren over bijvoorbeeld webhosting, zakelijke hosting of een VPS. Vanuit de support zorgen we ervoor dat we helder kunnen uitleggen hoe de oplossingen van elkaar verschillen en wat de voordelen zijn voor u of voor uw organisatie.</p>
        <h3>Neem contact met ons op</h3>
        <p>Komt u er online zelf niet uit en bieden ook de <router-link to="/klantenservice/veel-gestelde-vragen/">veel gestelde vragen</router-link> geen uitkomst? Neem dan contact met ons op via het onderstaande formulier. Door online uw naam, telefoonnummer, e-mailadres en vraag in te vullen kunnen we zo snel mogelijk contact met u opnemen. We zorgen er vanuit de support over hosting voor dat u snel antwoord krijgt op uw vraag.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';

export default {
  name: 'Support',
  components: {
    Breadcrumbs,
  },
}
</script>